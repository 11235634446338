import React from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Tag} from 'antd';

import * as AppContext from '../../AppContext';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  Edit,
} from '../../Widgets/Icon';
import EmailDetail from './Singup.EmailDetail';
import SettingsDetail from './Signup.SettingsDetail';

function SignupTable(props) {
  const app = React.useContext(AppContext.Context);
  const {activity, onCancel} = props;
  const [records, setRecords] = React.useState(null);
  const [selectedDetail, setSelectedDetail] = React.useState(null);

  const columns = [
    {
      title: '報名編號',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => <div>{record.id.slice(0, 6)}</div>,
    },
    {
      title: '會員名稱',
      dataIndex: 'member',
      key: 'member',
      render: (_, record, index) => <div>{record.profile.name}</div>,
    },
    {
      title: '面試狀態',
      dataIndex: 'INTERVIEW_STATE',
      key: 'INTERVIEW_STATE',
      render: (_, record, index) => {
        switch (record.profile.INTERVIEW_STATE) {
          case 'pending':
            return <Tag color="magenta">未面試</Tag>;
          case 'sent':
            return <Tag color="purple">面試通知已寄出</Tag>;
          case 'confirmed':
            return <Tag color="cyan">確認面試</Tag>;
          case 'rescheduled':
            return <Tag color="geekblue">面試需改期</Tag>;
          case 'refused':
            return <Tag color="">已不需面試</Tag>;
          case 'pass':
            return <Tag color="green">合格</Tag>;
          case 'candidate':
            return <Tag color="blue">備取</Tag>;
          case 'unqualified':
            return <Tag color="">不合格</Tag>;
          default:
            return record.INTERVIEW_STATE;
        }
      },
    },
    {
      title: '報名詳情',
      data: 'detail',
      key: 'detail',
      children: [
        {
          title: '時段',
          dataIndex: 'duration',
          render: (_, record, index) =>
            record.duration.map((item) => (
              <div style={{marginBottom: 15, whiteSpace: 'pre'}}>
                {item.date} {item.time[0]} - {item.time[1]}
              </div>
            )),
        },
        {
          title: '狀態',
          dataIndex: 'state',
          key: 'state',
          render: (_, record, index) => {
            return (
              <div style={{whiteSpace: 'pre-wrap'}}>
                {record.duration.map((item) => {
                  switch (item.state) {
                    case 'applied':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag
                            icon={<SyncOutlined color="blue" size={12} />}
                            color="blue">
                            已報名
                          </Tag>
                        </div>
                      );
                    case 'qualified':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag
                            icon={
                              <ClockCircleOutlined color="orange" size={12} />
                            }
                            color="orange">
                            已錄取
                          </Tag>
                        </div>
                      );
                    case 'unqualified':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag
                            icon={<CloseCircleOutlined color="red" size={12} />}
                            color="red">
                            未錄取
                          </Tag>
                        </div>
                      );
                    case 'candidate':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag
                            icon={
                              <MinusCircleOutlined color="purple" size={12} />
                            }
                            color="purple">
                            備取
                          </Tag>
                        </div>
                      );
                    case 'confirmed':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag
                            icon={
                              <CheckCircleOutlined color="green" size={12} />
                            }
                            color="green">
                            已確認
                          </Tag>
                        </div>
                      );
                    case 'refused':
                      return (
                        <div style={{marginBottom: 15, display: 'flex'}}>
                          <Tag icon={<CloseCircleOutlined size={12} />}>
                            已婉拒
                          </Tag>
                        </div>
                      );
                    default:
                      return `${item.state}\n`;
                  }
                })}
              </div>
            );
          },
        },
        {
          title: '排班設定',
          dataIndex: 'settings',
          key: 'settings',
          render: (_, record, index) => {
            return (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {record.duration.map((item) => (
                  <Button
                    shape="circle"
                    icon={<Edit color="#e18135" />}
                    style={{marginBottom: 8}}
                    onClick={() => {
                      setSelectedDetail({
                        timestamp: new Date().getTime(),
                        record: {
                          ...item,
                          id: record.id,
                          activity: record.activity,
                          profile: record.profile,
                        },
                        type: 'settings',
                      });
                    }}></Button>
                ))}
              </div>
            );
          },
        },
        {
          title: '通知',
          dataIndex: 'email_notified',
          key: 'email_notified',
          render: (_, record, index) => {
            return <div>{record.email_notified ? '已通知' : '未通知'}</div>;
          },
        },
        {
          title: '發信',
          dataIndex: 'action',
          key: 'action',
          render: (_, record) => (
            <Button
              onClick={() =>
                setSelectedDetail({
                  timestamp: new Date().getTime(),
                  record,
                  type: 'email',
                })
              }>
              郵件選單
            </Button>
          ),
        },
      ],
    },
  ];

  const dismissModal = () => {
    setSelectedDetail(null);
  };

  const staffGetParticipantListByActivityId = React.useCallback(
    async (id) => {
      app.actions.setLoading(true);
      try {
        let resp = await app.actions.staffGetParticipantListByActivityId(id);
        resp = resp.filter((r) => r.activity === id);
        let owners = resp.map((result) => result.user);
        let profiles = await app.actions.staffGetParticipantProfiles(owners);
        let result = resp.map((record) => {
          let profile = profiles.find(
            (profile) => profile.owner === record.user,
          );
          return {...record, profile};
        });

        setRecords(result);
      } catch (ex) {
        console.warn('fetch API error');
      }

      app.actions.setLoading(false);
    },
    [app.actions],
  );

  React.useEffect(() => {
    staffGetParticipantListByActivityId(activity.id);
  }, [staffGetParticipantListByActivityId, activity.id]);

  return (
    <Wrapper>
      <h3>{activity.name}</h3>

      {records && (
        <Table
          style={{whiteSpace: 'pre'}}
          columns={columns}
          dataSource={records}
        />
      )}

      <Modal
        title={null}
        footer={null}
        width={760}
        bodyStyle={{
          padding: 20,
          maxHeight: 700,
          height: '100%',
          overflow: 'auto',
        }}
        visible={!!selectedDetail}
        onOk={dismissModal}
        onCancel={dismissModal}>
        {selectedDetail?.type === 'email' && (
          <EmailDetail
            key={selectedDetail?.timestamp}
            activity={activity}
            record={selectedDetail?.record}
            onRefresh={() =>
              staffGetParticipantListByActivityId(
                selectedDetail?.record.activity,
              )
            }
            onCancel={() => setSelectedDetail(null)}
          />
        )}
        {selectedDetail?.type === 'settings' && (
          <SettingsDetail
            key={selectedDetail?.timestamp}
            record={selectedDetail?.record}
            onRefresh={() =>
              staffGetParticipantListByActivityId(
                selectedDetail?.record.activity,
              )
            }
            onCancel={() => setSelectedDetail(null)}
          />
        )}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default SignupTable;
