import React from 'react';
import styled from 'styled-components';
import {Button, Radio} from 'antd';

import * as AppContext from '../../AppContext';

function SettingsDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onRefresh, onCancel} = props;

  const [values, setValues] = React.useState({
    ...record,
  });

  const getRadioGroupProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        if (e.target.value === 'confirmed' || e.target.value === 'canceled') {
          return;
        } else if (
          values[field] === 'confirmed' ||
          values[field] === 'canceled'
        ) {
          return;
        } else {
          setValues({...values, [field]: e.target.value});
          return;
        }
      },
    };
  };

  const onConfirm = async () => {
    try {
      await app.actions.staffUpdateParticipantById(record.id, {
        duration: [{date: values.date, time: values.time}],
        state: values.state,
      });
      onRefresh();
      onCancel();
    } catch (err) {
      throw err;
    }
  };

  return (
    <Wrapper>
      <section>
        <h3>排班設定</h3>
        <div>
          {values.profile.name} 上班時段： {values.date} {values.time[0]} -{' '}
          {values.time[1]}
        </div>
        <Radio.Group
          {...getRadioGroupProps('state')}
          style={{marginBottom: 10}}
          buttonStyle="solid">
          <Radio.Button value="applied">已報名</Radio.Button>
          <Radio.Button value="qualified">已錄取</Radio.Button>
          <Radio.Button value="candidate">備取</Radio.Button>
          <Radio.Button value="unqualified">未錄取</Radio.Button>
          <Radio.Button
            value="confirmed"
            style={{
              cursor: 'not-allowed',
              color: record.state === 'confirmed' ? '#FFFFFF' : '#BEBEBE',
            }}>
            已確認
          </Radio.Button>
          <Radio.Button
            value="refused"
            style={{
              cursor: 'not-allowed',
              color: record.state === 'refused' ? '#FFFFFF' : '#BEBEBE',
            }}>
            已婉拒
          </Radio.Button>
        </Radio.Group>

        {(record.state !== 'confirmed' || record.state !== 'canceled') && (
          <div className="action-bar">
            <Button onClick={onCancel} style={{marginRight: 20}}>
              取消
            </Button>
            <Button onClick={onConfirm}>確認</Button>
          </div>
        )}
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {

    & > div {
      margin: 10px;
    }
  
    & > .action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default SettingsDetail;
