import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

import * as AppContext from '../../AppContext';
import {BellOutlined} from '../../Widgets/Icon';
import ActivityTable from '../../Components/Activity.Table';
import SignupTable from './Signup.Table';

function SignupMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const [records, setRecords] = React.useState(null);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const fetchActivityList = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let records = await app.actions.fetchActivityList();
      records = records.filter(
        (record) => record.status !== 'draft' && record.status !== 'publish',
      );
      setRecords(records);
    } catch (ex) {
      console.warn('fetch activity list error');
    }

    app.actions.setLoading(false);
  }, [app.actions]);

  React.useEffect(() => {
    fetchActivityList();
  }, [fetchActivityList]);

  return (
    <Wrapper>
      <div className="row-container">
        {!selectedRecord ? (
          <div className="header">
            <h1 className="title">報名管理</h1>
          </div>
        ) : (
          <div className="header">
            <Button
              type="primary"
              onClick={() => {
                fetchActivityList();
                setSelectedRecord(null);
              }}>
              回管理列表
            </Button>
          </div>
        )}
      </div>

      <div className="column-container">
        {!selectedRecord ? (
          <ActivityTable
            records={records}
            goToDetail={(record) => {
              setSelectedRecord(record);
            }}
          />
        ) : (
          <SignupTable
            activity={selectedRecord}
            onCancel={() => setSelectedRecord(null)}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .row-container {
    display: flex;

    & > .header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }
  }

  & > .column-container {
  }
`;

export default SignupMgrPage;
