import React from 'react';
import styled from 'styled-components';
import {Tabs, Input, Button} from 'antd';

import {
  EMPTY_EMAIL_TEMPALTE,
  SIGNUP_QUALIFIED_EMAIL_TEMPLATE,
  SIGNUP_CANDIDATE_NONE_INTERVIEW_EMAIL_TEMPLATE,
  SIGNUP_CANDIDATE_HAVE_INTERVIEW_EMAIL_TEMPLATE,
  SIGNUP_REFUSED_NONE_INTERVIEW_EMAIL_TEMPLATE,
  SIGNUP_REFUSED_HAVE_INTERVIEW_EMAIL_TEMPLATE,
} from '../../../content/email_template';
import * as AppContext from '../../AppContext';

function EmailDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {activity, record, onRefresh, onCancel} = props;
  const [activeTab, setActiveTab] = React.useState('1');
  const [values, setValues] = React.useState({
    qualified: {
      title: '',
      contnet: '',
    },
    candidate: {
      title: '',
      content: '',
    },
    refused: {
      title: '',
      content: '',
    },
    other: {
      title: '',
      content: '',
    },
  });

  const onConfirm = React.useCallback(async () => {
    try {
      if (activeTab === '1') {
        await app.actions.staffSendParticipantEmailById(
          record.id,
          values.qualified.title,
          values.qualified.content,
        );
      } else if (activeTab === '2') {
        await app.actions.staffSendParticipantEmailById(
          record.id,
          values.candidate.title,
          values.candidate.content,
        );
      } else if (activeTab === '3') {
        await app.actions.staffSendParticipantEmailById(
          record.id,
          values.refused.title,
          values.refused.content,
        );
      } else if (activeTab === '4') {
        await app.actions.staffSendParticipantEmailById(
          record.id,
          values.other.title,
          values.other.content,
        );
      }

      onRefresh();
      onCancel();
    } catch (err) {
      throw err;
    }
  }, [
    app.actions,
    record.id,
    activeTab,
    values.qualified,
    values.candidate,
    values.refused,
    values.other,
    onRefresh,
    onCancel,
  ]);

  return (
    <Wrapper>
      <section
        style={{
          paddingTop: 30,
        }}>
        <h3>郵件選單</h3>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(key) => setActiveTab(key)}>
          <Tabs.TabPane tab="已錄取通知" key="1">
            <div style={{marginBottom: 10}}>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    qualified: EMPTY_EMAIL_TEMPALTE(),
                  });
                }}>
                空白範本
              </Button>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    qualified: SIGNUP_QUALIFIED_EMAIL_TEMPLATE(
                      record.profile,
                      app.state.staff,
                      activity,
                    ),
                  });
                }}>
                馬上排班範本
              </Button>
            </div>
            <div className="field">
              <label>收件人</label>
              <Input disabled value={record.profile.email} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.qualified.title}
                onChange={(e) => {
                  setValues({
                    ...values,
                    qualified: {...values.qualified, title: e.target.value},
                  });
                }}
              />
            </div>
            <div className="field">
              <label>內文</label>
              <Input.TextArea
                value={values.qualified.content}
                onChange={(e) => {
                  setValues({
                    ...values,
                    qualified: {...values.qualified, content: e.target.value},
                  });
                }}
              />
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="備取通知" key="2">
            <div style={{marginBottom: 10}}>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    candidate: EMPTY_EMAIL_TEMPALTE(),
                  });
                }}>
                空白範本
              </Button>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    candidate: SIGNUP_CANDIDATE_NONE_INTERVIEW_EMAIL_TEMPLATE(
                      record.profile,
                      app.state.staff,
                      activity,
                    ),
                  });
                }}>
                未面試範本
              </Button>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    candidate: SIGNUP_CANDIDATE_HAVE_INTERVIEW_EMAIL_TEMPLATE(
                      record.profile,
                      app.state.staff,
                      activity,
                    ),
                  });
                }}>
                有面試範本
              </Button>
            </div>
            <div className="field">
              <label>收件人</label>
              <Input disabled value={record.profile.email} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.candidate.title}
                onChange={(e) => {
                  setValues({
                    ...values,
                    candidate: {...values.candidate, title: e.target.value},
                  });
                }}
              />
            </div>
            <div className="field">
              <label>內文</label>
              <Input.TextArea
                value={values.candidate.content}
                onChange={(e) => {
                  setValues({
                    ...values,
                    candidate: {...values.candidate, content: e.target.value},
                  });
                }}
              />
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="未錄取通知" key="3">
            <div style={{marginBottom: 10}}>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    refused: EMPTY_EMAIL_TEMPALTE(),
                  });
                }}>
                空白範本
              </Button>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    refused: SIGNUP_REFUSED_NONE_INTERVIEW_EMAIL_TEMPLATE(
                      record.profile,
                      app.state.staff,
                      activity,
                    ),
                  });
                }}>
                未面試範本
              </Button>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    refused: SIGNUP_REFUSED_HAVE_INTERVIEW_EMAIL_TEMPLATE(
                      record.profile,
                      app.state.staff,
                      activity,
                    ),
                  });
                }}>
                有面試範本
              </Button>
            </div>
            <div className="field">
              <label>收件人</label>
              <Input disabled value={record.profile.email} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.refused.title}
                onChange={(e) => {
                  setValues({
                    ...values,
                    refused: {...values.refused, title: e.target.value},
                  });
                }}
              />
            </div>
            <div className="field">
              <label>內文</label>
              <Input.TextArea
                value={values.refused.content}
                onChange={(e) => {
                  setValues({
                    ...values,
                    refused: {...values.refused, content: e.target.value},
                  });
                }}
              />
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="其他通知" key="4">
            <div className="field">
              <label>收件人</label>
              <Input disabled value={record.profile.email} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.other.title}
                onChange={(e) => {
                  setValues({
                    ...values,
                    other: {...values.other, title: e.target.value},
                  });
                }}
              />
            </div>
            <div className="field">
              <label>內文</label>
              <Input.TextArea
                value={values.other.content}
                onChange={(e) => {
                  setValues({
                    ...values,
                    other: {...values.other, content: e.target.value},
                  });
                }}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </section>

      <section>
        <div className="action-bar">
          <Button
            type="ghost"
            style={{minWidth: 125, height: 40}}
            onClick={onCancel}>
            取消
          </Button>
          <Button
            type="primary"
            style={{minWidth: 125, height: 40}}
            onClick={onConfirm}>
            送出
          </Button>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    & > h3 {
      font-size: 24px;
      color: #4a4a4a;
      line-height: 22px;
    }

    & .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      width: 100%;

      & > label {
        width: 80px;
      }

      & > input {
        flex: 1;
      }

      & > textarea {
        flex: 1;

        height: 300px;
        resize: none;
        white-space: pre-wrap;
      }
    }

    & > .field:last-child {
      margin-bottom: 50px;
    }

    & > .action-bar {
      border-top: 1px solid #cccccc;
      padding-top: 20px;

      display: flex;
      justify-content: flex-end;

      & > button {
        margin-right: 15px;
      }

      & button:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export default EmailDetail;
