import React from 'react';
import {navigate} from 'gatsby';
import * as Ant from 'antd';
import moment from 'moment';

function Table(props) {
  const {records, goToDetail} = props;

  const columns = [
    {
      title: '編號',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => {
        return <div>{record.id.slice(0, 6)}</div>;
      },
      responsive: ['sm'],
    },
    {title: '活動名稱', dataIndex: 'name', key: 'name'},
    {
      title: '活動日期',
      key: 'date',
      render: (_, record) => {
        return (
          <div>{`${moment(record.date_start).format('YYYY-MM-DD')} ~ ${moment(
            record.date_end,
          ).format('YYYY-MM-DD')}`}</div>
        );
      },
    },
    {
      title: '活動地點',
      dataIndex: 'address_name',
      key: 'address_name',
      responsive: ['sm'],
    },
    {
      title: '活動狀態',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        switch (record.status) {
          case 'draft':
            return '草稿';
          case 'publish':
            return '活動宣傳';
          case 'open':
            return '開放報名';
          case 'end':
            return '報名截止';
          default:
            return record.status;
        }
      },
      responsive: ['sm'],
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <Ant.Button
          onClick={() => {
            if (goToDetail) {
              goToDetail(record);
            } else {
              navigate(`/public?id=${record.id}`);
            }
          }}
          type="dashed">
          查看
        </Ant.Button>
      ),
    },
  ];

  return (
    <Ant.Table
      columns={columns}
      dataSource={records?.sort((a, b) => b.created - a.created)}
    />
  );
}

export default Table;
